import React, { useContext, useState, useEffect, useCallback } from 'react';
import { withRouter } from "react-router";
import { Switch } from 'react-router-dom'
import { Layout, Spin, Menu, Drawer, Button } from "antd";
import { MenuOutlined } from '@ant-design/icons';
import {Lang} from "@yulintu/freesia-react-component-common";
import { Application, Bootstrap } from "@yulintu/freesia-bootstrap";
import "./index.less";
const ClassicLayoutContent = function (props) {

    const context = useContext(Application.Context);

    const [flag, setFlag] = useState();
    const [busy, setBusy] = useState(true);
    const [collapsed, setCollapsed] = useState(props.metadata.props?.sider?.collapsed || false);
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [visibleDrawerSwitcher, setVisibleDrawerSwitcher] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [selectedKey, setSelectedKey] = useState();
    const [openKeys, setOpenKeys] = useState([]);
    const [overwriteProps, setOverwriteProps] = useState();
    const [manifests, setManifests] = useState([]);

    // console.log("Classic Layout Content", props);

    const onWindowSizeChanged = useCallback((e) => {
        const val = window.innerWidth < 900;
        // if (collapsed !== val)
        setCollapsed(val);

        setVisibleDrawerSwitcher(window.innerWidth < 501);
    }, []);

    useEffect(() => {
        onWindowSizeChanged();
        // console.log("addEventListener resize");

        window.addEventListener("resize", onWindowSizeChanged);

        return () => {
            // console.log("removeEventListener resize");
            window.removeEventListener("resize", onWindowSizeChanged);
        }
    }, [onWindowSizeChanged]);


    useEffect(() => {
        // console.log("Classic Layout Content useEffect");

        setBusy(true);
        Bootstrap.resolve(props.metadata.children, props.metadata).then(data => {
            setManifests(data);
        }).finally(() => {
            setBusy(false);
        });
    }, [props.metadata]);

    useEffect(() => {

        if (manifests && manifests.length) {
            Bootstrap.resetPermission(manifests, { userInfo: context.global.userInfo });
            setFlag({});
        }

    }, [props.metadata, manifests, context.global.userInfo]);

    const setGlobal = context.setGlobal;
    const onLoginSuccess = useCallback((userInfo) => {
        setGlobal({ userInfo });
    }, [setGlobal]);

    const renderMenuItem = (c, deep) => {

        c.alias = <Lang.Bottom style={props.metadata?.langContentStyle}>{c.alias}</Lang.Bottom>
        if (c.group) {
            return (
                <Menu.ItemGroup key={c.key} title={<div className="ant4-menu-item-group-title" title={
                    c.alias
                    } style={{ paddingLeft: (deep) * 24 }}>
                    {c.alias}
                    </div>}>
                    {
                        c.menu.map(d => {
                            if (d.enabled !== false && d.visible !== false)
                                return renderMenuItem(d, deep + 1);
                        }).filter(d => d)
                    }
                </Menu.ItemGroup>
            )
        }

        if (c.menu)
            return (
                <Menu.SubMenu popupClassName="layout-classic-content-sider-menu-popup" key={c.key} title={<>
                {c.alias}
                {c.addons ? renderAddons(c.addons) : null}</>} icon={c.svg ? <Application.SvgIcon type={c.svg} style={{}} /> : null} >
                    {
                        c.menu.map(d => {
                            if (d.enabled !== false && d.visible !== false)
                                return renderMenuItem(d, deep + 1);
                        }).filter(d => d)
                    }
                </Menu.SubMenu>
            )

        return <Menu.Item key={c.key} metadata={c} icon={c.svg ? <Application.SvgIcon type={c.svg} style={{}} /> : null}>
            <div style={{ display: "flex", alignItems: "center" }}>
                {Bootstrap.link(c)}
                {c.addons ? renderAddons(c.addons) : null}
            </div>
            </Menu.Item>

    }

    const renderSider = (matchItem, inDrawer) => {
        
        return (
            <Layout.Sider theme="light" {...props.metadata?.siderContentProps} className={`layout-classic-content-sider ${props.metadata?.siderContentClassName}`} collapsible={!inDrawer} style={{ ...props.metadata.props?.sider?.style, ...((selectedItem || matchItem)?.parentProps?.sider?.style)}} collapsed={inDrawer ? false : collapsed} onCollapse={e => setCollapsed(e)}>
                {
                    <div className='sider-title-frame' >
                        <div className='sider-title' title={props.sider?.title || props.metadata.alias} style={collapsed ? null : props.sider?.titleStyle} onClick={e => {
                            if (!inDrawer)
                                setCollapsed(!collapsed);

                        }}>
                            <Lang.Bottom>{props.sider?.title || props.metadata.alias}</Lang.Bottom>
                            {/* {props.sider?.title || props.metadata.alias} */}
                        </div>
                    </div>
                }
                <Menu
                    triggerSubMenuAction="hover"
                    mode="inline"
                    // defaultOpenKeys={inDrawer || !collapsed ? manifests.map(c => c.key) : []}
                    defaultOpenKeys={!inDrawer && collapsed ? [] : manifests.map(c => c.key)}
                    // defaultOpenKeys={manifests.map(c => c.key)}
                    openKeys={props.autoCollapseMenu ? (openKeys?.length ? openKeys : [manifests.find(c => c.menu?.length > 0)?.key]) : undefined}
                    onSelect={e => { setSelectedItem(e.item.props.metadata); setSelectedKey(e.key); if (props.autoCollapseMenu) setOpenKeys([e.item.props.metadata?.parent?.key]); setVisibleDrawer(false); }}
                    onOpenChange={e => setOpenKeys(e)}
                    selectedKeys={[(matchItem && matchItem.key) || selectedKey]}
                >
                    {
                        manifests.map(c => {
                            if (c.enabled !== false && c.visible !== false)
                                return renderMenuItem(c, 0);
                        }).filter(c => c)
                    }
                </Menu>
            </Layout.Sider>
        );
    }

    const renderDrawerSwitcher = () => {
        return (
            <Button className="sider-drawer-switcher" onClick={e => setVisibleDrawer(true)}><MenuOutlined /></Button>
        );
    }

    const renderAddons = (extra) => {

        const factory = context.factory;
        if (!factory)
            return null;

        const addons = (extra || []).map((c, i) => {
            if (!c.template)
                return null;

            let component = null;
            if (typeof c.template === "string")
                component = factory.load(c.template, { ...c.props, metadata: c, key: i }, { key: i });

            return component;

        }).filter(c => c);

        return addons;
    }

    const renderContent = (c, deep) => {

        let matchItem = Bootstrap.match(manifests, window.location.pathname, context.global.basename);
        if (!matchItem)
            matchItem = Bootstrap.findDefault(manifests);

        const theme = matchItem?.parentProps?.theme || props.theme;

        return (
            <Layout className={"layout-classic-content " + (theme === "white" || theme === "light" ? "white" : (theme || ""))} >
                {
                    <Drawer className="layout-classic-content-sider-drawer" placement="left" visible={visibleDrawer} onClose={e => setVisibleDrawer(false)} width={"auto"} closeIcon={null} headerStyle={{ display: "none" }}>
                        {visibleDrawerSwitcher ? renderSider(matchItem, true) : null}
                    </Drawer>
                }
                {
                    visibleDrawerSwitcher ? renderDrawerSwitcher() : renderSider(matchItem)
                }
                <Layout.Content className="layout-classic-content-content" style={props.metadata.props?.content?.style}>
                    <Switch>
                        {
                            Bootstrap.routes(context.factory, manifests, props.metadata, {
                                config: context.config,
                                onLoginSuccess: onLoginSuccess,
                            })
                        }
                    </Switch>
                </Layout.Content>
            </Layout>
        );
    }

    // console.log("render content");

    return busy ? <>
        <Spin wrapperClassName="full" spinning={busy} size="large">
            <div></div>
        </Spin>
    </> : renderContent();
}

export default withRouter(ClassicLayoutContent);